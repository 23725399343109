import { ClipSource } from '@air/api/types';
import { useSysadmin } from '@air/sysadmin';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { IMPORT_SOURCES, INTERNAL_IMPORT_SOURCES } from '~/constants/importSource';
import { ImportSourceType } from '~/utils/ImportUtils';

export const useExposeImportSources = () => {
  const { isInternal } = useSysadmin();
  const { showSharePointImportSource } = useFlags();

  const exposeImportSources = useMemo<ImportSourceType[]>(() => {
    if (isInternal) {
      return [...IMPORT_SOURCES, ...INTERNAL_IMPORT_SOURCES];
    }

    return showSharePointImportSource ? [...IMPORT_SOURCES, ClipSource.sharePoint] : IMPORT_SOURCES;
  }, [isInternal, showSharePointImportSource]);

  return { exposeImportSources };
};
