/**
 * This method returns asset and file uploads from a general uploads array
 * @param uploads all uploads array
 * @returns {@link GroupedUploads} uploads grouped by type - assets and file
 */ export const getAssetsAndFilesUploads = (uploads)=>uploads.reduce((acc, curr)=>{
        if (curr.isNonMedia) {
            acc.fileUploads.push(curr);
        } else {
            acc.assetUploads.push(curr);
        }
        return acc;
    }, {
        assetUploads: [],
        fileUploads: []
    });
