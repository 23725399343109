import { NEW_ACTION_BUTTON } from "~/constants/testIDs";
import { getImportLogo } from "~/utils/dropdownMenuOptions/getAddMenuOptions";
import { getDropdownMenuOption } from "./getDropdownMenuOption";
export const getActionMenuOptions = (param)=>{
    let { onImportFrom, onUploadFilesFromComputer, onUploadFoldersFromComputer, onCreateBoard, onAddSubBoard, onCollectContent, exposeImportSources } = param;
    const options = [];
    if (onUploadFilesFromComputer) {
        options.push({
            ...getDropdownMenuOption("upload-files"),
            id: "".concat(NEW_ACTION_BUTTON, "-upload-files"),
            onSelect: onUploadFilesFromComputer,
            type: "item"
        });
    }
    if (onUploadFoldersFromComputer) {
        options.push({
            ...getDropdownMenuOption("upload-folders"),
            id: "".concat(NEW_ACTION_BUTTON, "-upload-folders"),
            onSelect: onUploadFoldersFromComputer,
            type: "item"
        }, {
            id: "upload-folders-separator",
            type: "separator"
        });
    }
    if (onCollectContent) {
        options.push({
            ...getDropdownMenuOption("content-collection"),
            onSelect: onCollectContent,
            description: "Collect content directly from anyone, no Air account needed.",
            type: "item"
        }, {
            id: "content-collection-separator",
            type: "separator"
        });
    }
    if (onImportFrom) {
        options.push({
            id: "import-title",
            label: "Import from...",
            type: "title"
        });
        exposeImportSources.forEach((source)=>{
            const id = source === "googleDrive" ? "import-from-drive" : "import-from-".concat(source);
            options.push({
                ...getDropdownMenuOption(id),
                prefix: getImportLogo(source),
                id: "".concat(NEW_ACTION_BUTTON, "-").concat(source, "-import"),
                onSelect: ()=>onImportFrom(id),
                type: "item"
            });
        });
    }
    if (onAddSubBoard) {
        if (options.length) {
            options.push({
                id: "add-sub-board-separator",
                type: "separator"
            });
        }
        options.push({
            ...getDropdownMenuOption("add-sub-board"),
            id: "".concat(NEW_ACTION_BUTTON, "-add-sub-board"),
            onSelect: onAddSubBoard,
            type: "item"
        });
    }
    if (onCreateBoard) {
        options.push({
            id: "create-new-board-separator",
            type: "separator"
        }, {
            ...getDropdownMenuOption("create-new-board"),
            id: "".concat(NEW_ACTION_BUTTON, "-Create new board"),
            onSelect: onCreateBoard,
            type: "item"
        });
    }
    return options;
};
